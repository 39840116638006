import { api } from 'utils';
import { IChat, IMessage, IRequestConfig } from 'types';
import { dispatch, updateCurrentMemberTaskChatId } from 'store';
import { addMessageToChat, loadChatMessages, setIsChatLoading } from './chat.slice';

export function chat_getChatId(taskId: string) {
  const onResolve = async (res: any) => {
    const chatId: string = await res.data;
    if (chatId) {
      dispatch(updateCurrentMemberTaskChatId(chatId));
      chat_getChat(chatId);
    }
  };

  const config: IRequestConfig = {
    method: 'get',
    url: `tasks/v1/Task/${taskId}/chatId`,
    onResolve
  };
  api.processRequest(config);
}

export function chat_getChat(chatId: string | undefined) {
  const onResolve = async (res: any) => {
    const chat: IChat = await res.data;
    dispatch(loadChatMessages(chat));
  };

  const onReject = () => {
    dispatch(setIsChatLoading(false));
  };

  const config: IRequestConfig = {
    method: 'get',
    url: `chat/v1/GetChat/${chatId}`,
    onResolve,
    onReject
  };
  api.processRequest(config);
}

export function chat_createChat(taskId: string, message?: IMessage) {
  return new Promise((resolve, reject) => {
    const onResolve = async (res: any) => {
      const chat: IChat = await res.data;
      if (chat.messages && chat.messages.length > 0) {
        dispatch(loadChatMessages(chat));
      }

      dispatch(updateCurrentMemberTaskChatId(chat.id));
      resolve(chat);
    };

    const onReject = (err: any) => {
      reject(err);
    };

    const requestObject = {
      taskId: taskId,
      initialChatMessage: message
    };

    const config: IRequestConfig = {
      method: 'post',
      url: 'tasks/v1/Task/initiateChat',
      data: requestObject,
      onResolve,
      onReject
    };
    api.processRequest(config);
  });
}

export function chat_sendChatMessage(message: IMessage) {
  return new Promise((resolve, reject) => {
    const onResolve = async (res: any) => {
      const data: IMessage = await res.data;
      dispatch(addMessageToChat(data));
      resolve(true);
    };

    const onReject = (err: unknown) => {
      chat_addFailedMessage(message);
      reject(err);
    };

    const config: IRequestConfig = {
      method: 'post',
      url: 'chat/v1/SendMessage',
      data: message,
      onResolve,
      onReject
    };
    api.processRequest(config);
  });
}

export function chat_addFailedMessage(message: IMessage) {
  const failedMessage = {
    ...message,
    isFailed: true
  };
  dispatch(addMessageToChat(failedMessage));
}
