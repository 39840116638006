import MainCard from 'components/MainCard';
import { CardContent, Grid, Typography } from '@mui/material';
import Breach from './Buttons/Breach';
import Warning from './Buttons/Warning';
import Compliant from './Buttons/Compliant';
import dashboardContext from 'views/Dashboard/useDashboardContext';
import PriorityFilter from './Charts/PriorityFilter';
import VIPFilter from './Charts/VIPFilter';
import { loggedInUser } from 'hooks';

const TaskFilters: React.VFC = () => {
  const { hasTeams } = loggedInUser.useController();
  const { hasTasks, summaryFilter, isTasksLoading, hasSummaryTasks } = dashboardContext.useController();

  if (!hasTeams) {
    return null;
  }

  if (!hasTasks || (summaryFilter && !hasSummaryTasks)) {
    return (
      <CardContent>
        <Grid item xs={12} style={{ height: '100%', minHeight: '375px' }}>
          <Typography variant="h5" textAlign="center" marginTop="100px">
            {isTasksLoading ? 'Loading Dashboard...' : 'No tasks found'}
          </Typography>
        </Grid>
      </CardContent>
    );
  }

  return (
    <MainCard contentSX={{ p: 2.25 }} title={<Typography variant="h5">Quick Filters</Typography>}>
      <Grid container rowSpacing={5}>
        <Grid item xs={12}>
          <Grid container columnSpacing={5}>
            <Grid item xs={4}>
              <Breach />
            </Grid>
            <Grid item xs={4}>
              <Warning />
            </Grid>
            <Grid item xs={4}>
              <Compliant />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} textAlign="center">
          <VIPFilter />
        </Grid>
        <Grid item xs={6} textAlign="center">
          <PriorityFilter />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default TaskFilters;
