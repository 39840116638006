import { useMemo, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { dispatch, resetMemberState } from 'store';
import { CrownFilled } from '@ant-design/icons';
import { type MRT_ColumnDef } from 'material-react-table';
import { ITask } from 'types';
import UserAvatar from 'components/UserAvatar';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { SLAIcon } from 'views/Member/components/SLAField';
import { convertUtcToLocal } from 'utils/helpers/dateTimeHelper';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import dashboardContext from 'views/Dashboard/useDashboardContext';
import { highlightColorEnum } from 'components/TextHighlight';
import ShowMoreTextContent from 'components/ShowMoreTextContent';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const useTaskGrid = () => {
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const { filteredTasks, slaFilter, priorityFilter } = dashboardContext.useController();

  const slaFilterOptions = [...new Set(filteredTasks.map((t) => t.slaStatus ?? '').sort())];
  const taskTypeFilterOptions = [...new Set(filteredTasks.map((t) => t.taskTypeName ?? '').sort())];
  const taskStatusFilterOptions = [...new Set(filteredTasks.map((t) => t.taskStatusName ?? '').sort())];
  const taskPriorityFilterOptions = [...new Set(filteredTasks.map((t) => t.taskPriorityName ?? '').sort())];
  const taskSourceFilterOptions = [...new Set(filteredTasks.map((t) => t.taskSourceName ?? '').sort())];
  const teamOwnerFilterOptions = [...new Set(filteredTasks.map((t) => t.assignedToGroupName ?? 'unassigned').sort())];
  const userOwnerFilterOptions = [...new Set(filteredTasks.map((t) => t.assignedToUserName ?? 'unassigned').sort())];
  const booleanFilterOptions = ['Yes', 'No'];

  const assigneeLink = (task: ITask) => {
    const { assignedToUserName }: any = task;
    const assignee = assignedToUserName ?? 'unassigned';
    const StyledLink = styled('span')(({ theme }) => ({
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.secondary.main
      }
    }));

    return (
      <StyledLink onClick={() => setSelectedTask(task)} title={`Click to ${assignee !== 'unassigned' ? 'Re' : ''}assign this task`}>
        {assignee}
      </StyledLink>
    );
  };

  const memberLink = (value: any) => {
    const { vipMember, memberName }: any = value;
    return (
      <div>
        {memberName}
        &nbsp;
        {vipMember && <CrownFilled style={{ color: theme.palette.primary.main }} />}
      </div>
    );
  };

  const navigateMember = (task: ITask): any => {
    dispatch(resetMemberState());
    navigate(`/member`, {
      state: {
        paramsData: {
          taskId: task.taskId,
          memberId: task.memberId
        }
      }
    });
  };

  const taskParticipants = (parts: any) => {
    const participants = parts.slice();
    if (participants?.length) {
      const maxToDisplay = 2; // total # of avatars to show
      let plusHowMany = 0;
      if (participants.length > maxToDisplay) {
        plusHowMany = participants.length - maxToDisplay; // 3-2 = 1
        participants.splice(maxToDisplay - 1, plusHowMany);
      }
      const out = participants.map((part: any, idx: number) => {
        const { displayName } = part ?? '';
        const iconSize = idx !== 0 ? 'sm' : 'md';
        return <UserAvatar key={idx} displayName={displayName} size={iconSize} />;
      });
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {out}
          {plusHowMany !== 0 && <>&nbsp; +{plusHowMany}</>}
        </div>
      );
    }
  };

  const columns: any = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'SLA',
        id: 'slaStatus',
        size: 50,
        enableColumnFilter: !Boolean(slaFilter),
        enableSorting: !Boolean(slaFilter),
        filterVariant: 'select',
        filterSelectOptions: slaFilterOptions,
        filterFn: (row: any, id, filterValue): boolean => {
          if (!filterValue) {
            return true;
          }

          return row.original.slaStatus === filterValue;
        },
        accessorFn: (originalRow: any) => originalRow.slaStatus,
        Cell: ({ cell, row }: any) => {
          return <SLAIcon slaStatus={row.original.slaStatus} />;
        }
      },
      {
        header: 'New Reply',
        id: 'hasNewReply',
        maxSize: 50,
        filterVariant: 'select',
        filterSelectOptions: booleanFilterOptions,
        accessorFn: (originalRow: any) => originalRow.hasNewReply,
        Cell: ({ cell, row }: any) => {
          if (!cell.getValue()) {
            return null;
          }

          return <MessageOutlinedIcon />;
        },
        enableColumnFilter: false
      },
      {
        header: 'Priority',
        id: 'taskPriorityName',
        filterVariant: 'select',
        maxSize: 50,
        enableColumnFilter: !priorityFilter,
        enableSorting: !priorityFilter,
        filterSelectOptions: taskPriorityFilterOptions,
        filterFn: (row: any, id, filterValue): boolean => {
          if (!filterValue) {
            return true;
          }

          return row.original.taskPriorityName === filterValue;
        },
        accessorFn: (originalRow: any) => originalRow.taskPriorityName,
        Cell: ({ cell }: any) => {
          let bgcolor: any;
          let description = cell.getValue() ?? '';
          switch (description.toLowerCase()) {
            case 'routine':
              bgcolor = highlightColorEnum.Routine;
              break;
            case 'high':
              bgcolor = highlightColorEnum.High;
              break;
            default:
              bgcolor = highlightColorEnum.Emergency;
          }

          return (
            <Box
              component="span"
              sx={() => ({
                backgroundColor: bgcolor,
                borderRadius: '0.25rem',
                color: '#fff',
                maxWidth: '5ch',
                p: '0.25rem'
              })}
            >
              {cell.getValue()}
            </Box>
          );
        }
      },
      {
        header: 'Created Date',
        id: 'createdDate',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        maxSize: 180,
        accessorFn: (row) => new Date(row.createdDate),
        Cell: ({ cell, row }) => {
          return (
            <Box
              component="span"
              sx={() => ({
                borderRadius: '0.25rem',
                maxWidth: '9ch',
                p: '0.25rem'
              })}
            >
              {convertUtcToLocal(row.original.createdDate).format('MMM Do YYYY, h:mm a')}
            </Box>
          );
        },
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              value={column.getFilterValue() as any}
            />
          </LocalizationProvider>
        )
      },
      {
        header: 'Member Name',
        id: 'memberName',
        accessorFn: (originalRow: any) => ({
          vipMember: originalRow.vipMember,
          memberName: originalRow.memberName
        }),
        filterFn: (row: any, id, filterValue): boolean => {
          const memberName = row.getValue(id)?.memberName;
          if (!memberName) {
            return false;
          }

          return memberName.toLowerCase().includes(String(filterValue).toLowerCase());
        },
        Cell: ({ cell }) => {
          return memberLink(cell.getValue());
        }
      },
      {
        header: 'Group',
        accessorKey: 'assignedToGroupName',
        id: 'assignedToGroupName',
        enableHiding: true,
        filterVariant: 'multi-select',
        filterSelectOptions: teamOwnerFilterOptions,
        accessorFn: (originalRow: any) => originalRow.assignedToGroupName,
        Cell: ({ cell }: any) => cell.getValue()
      },
      {
        header: 'Owner',
        id: 'assignedToUserName',
        accessorKey: 'assignedToUserName',
        filterVariant: 'multi-select',
        filterSelectOptions: userOwnerFilterOptions,
        accessorFn: (originalRow: any) => originalRow.assignedToUserName ?? 'unassigned',
        Cell: ({ cell, row }: any) => assigneeLink(row.original)
      },
      {
        header: 'Active',
        id: 'activeMember',
        maxSize: 50,
        filterVariant: 'select',
        filterSelectOptions: booleanFilterOptions,
        accessorFn: (originalRow: any) => (originalRow.activeMember ? 'yes' : 'no'),
        Cell: ({ cell }: any) => cell.getValue(),
        enableColumnFilter: false
      },
      {
        header: 'Source',
        id: 'taskSourceName',
        maxSize: 50,
        filterVariant: 'select',
        filterSelectOptions: taskSourceFilterOptions,
        filterFn: (row: any, id, filterValue): boolean => {
          if (!filterValue) {
            return true;
          }

          return row.original.taskSourceName === filterValue;
        },
        accessorFn: (originalRow: any) => (originalRow.taskSourceName === undefined ? 'unknown' : originalRow.taskSourceName),
        Cell: ({ cell }: any) => cell.getValue()
      },
      {
        header: 'Type',
        id: 'taskTypeName',
        maxSize: 50,
        filterVariant: 'select',
        filterSelectOptions: taskTypeFilterOptions,
        filterFn: (row: any, id, filterValue): boolean => {
          if (!filterValue) {
            return true;
          }

          return row.original.taskTypeName === filterValue;
        },
        accessorFn: (originalRow: any) => originalRow.taskTypeName,
        Cell: ({ cell }: any) => cell.getValue()
      },
      {
        header: 'Description',
        accessorKey: 'taskDescription',
        minSize: 300,
        accessorFn: (originalRow: any) => originalRow.taskDescription,
        Cell: ({ cell }: any) => <ShowMoreTextContent textContent={cell.getValue()} showLines={2} />,
        enableColumnFilter: false
      },
      {
        header: 'Status',
        id: 'taskStatusName',
        maxSize: 50,
        filterVariant: 'select',
        filterSelectOptions: taskStatusFilterOptions,
        filterFn: (row: any, id, filterValue): boolean => {
          if (!filterValue) {
            return true;
          }

          return row.original.taskStatusName === filterValue;
        },
        accessorFn: (originalRow: any) => originalRow.taskStatusName,
        Cell: ({ cell }: any) => cell.getValue()
      },
      {
        header: 'Task ID',
        id: 'taskName',
        maxSize: 50,
        accessorKey: 'taskName',
        Cell: ({ cell }: any) => cell.getValue()
      },
      {
        header: 'Participants',
        accessorKey: 'participants',
        maxSize: 150,
        Cell: ({ cell }: any) => taskParticipants(cell.getValue() ?? []),
        enableColumnFilter: false
      },
      {
        header: 'VIP Member',
        accessorKey: 'vipMember',
        id: 'vipMember',
        filterVariant: 'select',
        filterSelectOptions: booleanFilterOptions,
        accessorFn: (originalRow: any) => (originalRow.vipMember ? 'yes' : 'no'),
        Cell: ({ cell }: any) => cell.getValue(),
        enableColumnFilter: false
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredTasks]
  );

  return {
    navigateMember,
    columns,
    filteredTasks,
    selectedTask,
    setSelectedTask
  };
};

export default useTaskGrid;
