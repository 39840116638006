import { Button, useTheme } from '@mui/material';
import { useCurrentWidth } from 'hooks';
import { DASHBOARD_SUMMARY_VIEW } from 'types';
import dashboardContext from 'views/Dashboard/useDashboardContext';

export const TeamTasksButton: React.VFC = () => {
  const { teamTasksCount, summaryFilter, setSummaryFilter } = dashboardContext.useController();
  const teamTasksActive = summaryFilter === DASHBOARD_SUMMARY_VIEW.TEAM;

  return (
    <SummaryTaskButtons
      isActive={teamTasksActive}
      buttonStyle={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
      onButtonClicked={() => setSummaryFilter(DASHBOARD_SUMMARY_VIEW.TEAM)}
      label={`Team Tasks (${teamTasksCount})`}
    />
  );
};

export const MyTasksButton: React.VFC = () => {
  const { myTasksCount, summaryFilter, setSummaryFilter } = dashboardContext.useController();
  const myTasksActive = summaryFilter === DASHBOARD_SUMMARY_VIEW.USER;

  return (
    <SummaryTaskButtons
      isActive={myTasksActive}
      buttonStyle={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
      onButtonClicked={() => setSummaryFilter(DASHBOARD_SUMMARY_VIEW.USER)}
      label={`My Tasks (${myTasksCount})`}
    />
  );
};

interface SummaryTaskButtonsProps {
  isActive: boolean;
  buttonStyle: any;
  onButtonClicked: () => void;
  label: string;
}

const SummaryTaskButtons: React.VFC<SummaryTaskButtonsProps> = (props) => {
  const { isActive, buttonStyle, onButtonClicked, label } = props;

  const browserWidth = useCurrentWidth();
  const theme = useTheme();
  const sx = { backgroundColor: theme.palette.secondary[800] };
  const xsBreakpoint = theme.breakpoints.values.md;
  const xsButtons = browserWidth <= xsBreakpoint;

  return (
    <Button
      variant={isActive ? 'contained' : 'outlined'}
      style={buttonStyle}
      sx={isActive ? sx : null}
      color="secondary"
      size={xsButtons ? 'extraSmall' : 'small'}
      onClick={onButtonClicked}
    >
      {label}
    </Button>
  );
};
