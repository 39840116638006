import { IActivityItem } from 'types';
import ApiService from './api-service';

interface PaginatedActivitiesList {
  activities: IActivityItem[];
  activityCount: number;
  pageSize: number;
  pageIncrement: number;
}

export class ActivityService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getRecentActivities(groupIds: string[], search: string, pageIncrement: number, pageSize: number): Promise<PaginatedActivitiesList> {
    return this.apiService
      .post(`activity/v1/Activity/myActivities`, {
        groupIds,
        pageSize,
        pageIncrement,
        search
      })
      .then((r) => r?.data);
  }

  getTaskActivities(taskId: string): Promise<IActivityItem[]> {
    return this.apiService.get(`activity/v1/Activity/${taskId}`).then((r) => r?.data);
  }
}

export default ActivityService;
