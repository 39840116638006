import { SearchAndShow } from '../components/SearchAndShow';
import { useSearchGroupUsers } from './use-search-group-users';
import { SearchProps } from '../definitions';

export const SearchGroupUsers: React.VFC<SearchProps> = (props) => {
  const { id, value, onSelect, showLoading, defaultOptions, searchOptions, showOptions, onClose, isDisabled, errors, touched } =
    useSearchGroupUsers(props);

  return (
    <SearchAndShow
      id={id}
      placeHolder="Assign Task To..."
      isDisabled={isDisabled}
      onSelect={onSelect}
      value={value}
      defaultOptions={defaultOptions}
      searchOptions={searchOptions}
      showLoading={showLoading}
      showOptions={showOptions}
      onClose={onClose}
      errors={errors}
      touched={touched}
    />
  );
};
