import {
  CardContent,
  Grid,
  TextField,
  List,
  ListItemButton,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MainCard from 'components/MainCard';
import ActivityItem from './components/ActivityItem';
import { useRecentActivity } from './use-recent-activity';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoActivityIndicator } from './no-activity-indicator';
import { LoadingMoreIndicator } from './loading-more-indicator';
import RefreshIcon from '@mui/icons-material/Refresh';

// ===========================|| DATA WIDGET - USER ACTIVITY CARD ||=========================== //

const RecentActivity = () => {
  const recentActivity = useRecentActivity();

  return (
    <MainCard
      title={
        <Stack direction="row" alignItems="center">
          <Typography variant="h5">Recent Activity</Typography>
          <Tooltip title="Refresh">
            <IconButton aria-label="refresh" size="large" onClick={recentActivity.reload}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      }
      content={false}
      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', position: 'relative' }}
    >
      <CardContent>
        {true && (
          <>
            <Grid container spacing={0} rowSpacing={5}>
              <Grid item xs={12}>
                <TextField
                  id="helper-text-basic"
                  value={recentActivity.searchString}
                  onChange={(e) => recentActivity.setSearchString(e.target.value)}
                  style={{ width: '100%' }}
                  placeholder="Search Activity"
                  InputProps={{ endAdornment: recentActivity.isActivityLoading ? <CircularProgress /> : <SearchIcon /> }}
                />
              </Grid>
            </Grid>
            <MainCard sx={{ mt: 2, minHeight: '500px', maxHeight: '500px', overflowY: 'scroll' }} id="activityScroller" content={false}>
              {recentActivity.hasActivities && (
                <InfiniteScroll
                  dataLength={recentActivity.activities.length}
                  next={recentActivity.fetchNextPage}
                  hasMore={recentActivity.hasNextPage}
                  loader={<LoadingMoreIndicator />}
                  scrollableTarget="activityScroller"
                >
                  <List sx={{ p: 0, '& .MuiListItemButton-root': { py: 1 } }}>
                    {recentActivity.activities.map((item, index: number) => {
                      return (
                        <ListItemButton key={index}>
                          <ActivityItem {...item} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </InfiniteScroll>
              )}
              <NoActivityIndicator
                search={recentActivity.searchString}
                visible={!recentActivity.hasActivities && !recentActivity.isActivityLoading}
              />
            </MainCard>
          </>
        )}
      </CardContent>
    </MainCard>
  );
};

export default RecentActivity;
