import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { SLA_STATUS } from 'types';

interface ApexSLAChartProps {
  slaCounts: number[];
  onSLASelected: (slaStatus: SLA_STATUS) => void;
}

const ApexSLAChart: React.VFC<ApexSLAChartProps> = (props) => {
  const { slaCounts, onSLASelected } = props;
  const theme = useTheme();
  const { primary } = theme.palette.text;
  const line = theme.palette.divider;
  const backColor = theme.palette.background.paper;
  const slaCompliant = theme.palette.secondary[600];
  const slaBreach = theme.palette.error.main;
  const slaWarning = theme.palette.warning.main;

  const setGridViewFilters = (idx: number) => {
    switch (idx) {
      case 0:
        onSLASelected(SLA_STATUS.BREACH);
        break;
      case 1:
        onSLASelected(SLA_STATUS.WARNING);
        break;
      default:
        onSLASelected(SLA_STATUS.COMPLIANT);
    }
  };

  const options: ChartProps = useMemo(() => {
    return {
      labels: ['SLA Breaches', 'SLA Warnings', 'Compliant'],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '20px',
          fontWeight: 'bolder'
        },
        formatter: (_val: number, opts: any) => {
          const { seriesIndex, w } = opts;
          return w.globals.seriesTotals[seriesIndex];
        }
      },
      chart: {
        type: 'donut',
        width: 200,
        height: 200,
        events: {
          dataPointSelection: (_event: any, _chartContext: any, seriesIndex: any) => {
            const idx = seriesIndex.dataPointIndex;
            setTimeout(() => setGridViewFilters(idx), 200);
          },
          dataPointMouseEnter: (event: any) => {
            event.target.style.cursor = 'pointer';
          }
        }
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontWeight: 800,
                fontSize: '2.0rem'
              },
              value: {
                show: true,
                fontWeight: 800,
                fontSize: '1.5rem'
              },
              total: {
                show: true,
                fontSize: '1.25rem'
              }
            }
          }
        }
      },
      colors: [slaBreach, slaWarning, slaCompliant],
      xaxis: {
        labels: {
          style: {
            colors: [primary, primary, primary]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      legend: false,
      stroke: {
        colors: [backColor]
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backColor, line, primary, slaCounts, setGridViewFilters, slaBreach, slaCompliant, slaWarning]);

  return <ReactApexChart options={options} series={slaCounts} type="donut" />;
};

export default ApexSLAChart;
