import { useTheme } from '@mui/material/styles';
import { SLA_STATUS } from 'types';
import { FireOutlined } from '@ant-design/icons';
import SLAButton from './SLAButton';
import dashboardContext from 'views/Dashboard/useDashboardContext';

const Breach: React.VFC = () => {
  const { slaCounts, slaFilter, setSLAFilter } = dashboardContext.useController();
  const theme = useTheme();

  return (
    <SLAButton
      taskCount={slaCounts[0]}
      onClick={() => setSLAFilter(SLA_STATUS.BREACH)}
      title="SLA Breaches"
      secondary="8"
      color={theme.palette.error.main}
      iconPrimary={FireOutlined}
      isActive={slaFilter === SLA_STATUS.BREACH}
    />
  );
};

export default Breach;
