import MainCard from 'components/MainCard';
import { Grid, Typography } from '@mui/material';
import { loggedInUser } from 'hooks';
import TaskFilters from './components/TaskFilters';
import MyTeamSelector from './components/MyTeamsSelector';
import SummaryTaskOptions from './components/SummaryTaskOptions';

const Summary: React.VFC = () => {
  const { userName } = loggedInUser.useController();

  return (
    <MainCard
      contentSX={{ p: 2.25 }}
      title={
        <Grid container>
          <Grid item xs>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">Hello, {userName}</Typography>
              </Grid>
              <Grid item xs={12}>
                <MyTeamSelector />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto" textAlign="right">
            <SummaryTaskOptions />
          </Grid>
        </Grid>
      }
    >
      <TaskFilters />
    </MainCard>
  );
};

export default Summary;
