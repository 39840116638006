import { Stack, Typography } from '@mui/material';
import dashboardContext from 'views/Dashboard/useDashboardContext';
import ApexSLAChart from './ApexSLAChart';
import EmptyFilter from './EmptyFilter';

const VIPFilter: React.VFC = () => {
  const { vipRedrawKey, hasVipTasks, vipCounts, setVIPFilter } = dashboardContext.useController();

  if (!hasVipTasks) {
    return <EmptyFilter label="VIP Member Tasks" />;
  }

  return (
    <Stack data-testid={'member-filter-vip'}>
      <Typography variant="h5">VIP Member Tasks</Typography>
      <ApexSLAChart key={`vipFilter${vipRedrawKey}`} slaCounts={vipCounts} onSLASelected={setVIPFilter} />
    </Stack>
  );
};

export default VIPFilter;
