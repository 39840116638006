import { useTheme } from '@mui/material/styles';
import { SLA_STATUS } from 'types';
import { CheckCircleOutlined } from '@ant-design/icons';
import SLAButton from './SLAButton';
import dashboardContext from 'views/Dashboard/useDashboardContext';

const Compliant: React.VFC = () => {
  const { slaCounts, slaFilter, setSLAFilter } = dashboardContext.useController();
  const theme = useTheme();

  return (
    <SLAButton
      taskCount={slaCounts[2]}
      onClick={() => setSLAFilter(SLA_STATUS.COMPLIANT)}
      title="Compliant Tasks"
      secondary="40"
      color={theme.palette.secondary[600]}
      iconPrimary={CheckCircleOutlined}
      isActive={slaFilter === SLA_STATUS.COMPLIANT}
    />
  );
};

export default Compliant;
