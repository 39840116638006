import { useTheme } from '@mui/material/styles';
import { SLA_STATUS } from 'types';
import { WarningOutlined } from '@ant-design/icons';
import SLAButton from './SLAButton';
import dashboardContext from 'views/Dashboard/useDashboardContext';

const Warning: React.VFC = () => {
  const { slaCounts, slaFilter, setSLAFilter } = dashboardContext.useController();
  const theme = useTheme();

  return (
    <SLAButton
      taskCount={slaCounts[1]}
      onClick={() => setSLAFilter(SLA_STATUS.WARNING)}
      title="SLA Warnings"
      secondary="25"
      color={theme.palette.warning.main}
      iconPrimary={WarningOutlined}
      isActive={slaFilter === SLA_STATUS.WARNING}
    />
  );
};

export default Warning;
