import { Grid } from '@mui/material';
import { loggedInUser } from 'hooks';
import ReloadTasks from './ReloadTasks';
import { MyTasksButton, TeamTasksButton } from './SummaryTaskButtons';

const SummaryTaskOptions: React.VFC = () => {
  const { hasTeams } = loggedInUser.useController();

  return !hasTeams ? null : (
    <Grid container>
      <Grid item xs={12}>
        <MyTasksButton />
        <TeamTasksButton />
        <ReloadTasks />
      </Grid>
    </Grid>
  );
};

export default SummaryTaskOptions;
