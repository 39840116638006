import { Alert, AlertTitle, MenuItem, Select, Stack, Typography } from '@mui/material';
import { SuggestContactITLink } from 'components/ContactITLink';
import { loggedInUser } from 'hooks';
import dashboardContext from 'views/Dashboard/useDashboardContext';

const MyTeamSelector: React.VFC = () => {
  const { myTeams, hasTeams } = loggedInUser.useController();
  const { activeTeamId, setCurrentTeam } = dashboardContext.useController();

  if (!hasTeams) {
    return (
      <Alert severity="error">
        <AlertTitle>You are not assigned to a team.</AlertTitle>
        <SuggestContactITLink />
      </Alert>
    );
  }

  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="h5">Dashboard</Typography>
      <Select
        variant="standard"
        id="myTeams"
        label="MyTeam"
        value={activeTeamId}
        onChange={(g) => setCurrentTeam(g.target.value as string)}
      >
        {myTeams.length > 1 && (
          <MenuItem key={'ALL'} value={'ALL'}>
            {'All My Teams'}
          </MenuItem>
        )}
        {myTeams?.map((t) => {
          return (
            <MenuItem key={t.id} value={t.id}>
              {t.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export default MyTeamSelector;
