import { IActivityItem } from 'types';
import { useActivityCache } from 'hooks';
import { useDebouncedState } from 'sollishealth.core.react';

interface RecentActivity {
  searchString: string;
  setSearchString: (s: string) => void;
  isActivityLoading: boolean;
  activities: IActivityItem[];
  hasActivities: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  reload: () => void;
}

export const useRecentActivity = (): RecentActivity => {
  const [debouncedSearchString, setSearchString, searchString] = useDebouncedState('');
  const { isActivityLoading, activities, hasActivities, hasNextPage, fetchNextPage, reload } = useActivityCache(debouncedSearchString);

  return {
    searchString,
    setSearchString,
    isActivityLoading,
    activities,
    hasActivities,
    hasNextPage,
    fetchNextPage,
    reload
  };
};

export default useRecentActivity;
