import React from 'react';
import { MILLISECONDS, IActivityItem } from 'types';
import { loggedInUser, useActivityService } from 'hooks';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { chunk, flatten } from 'lodash';

interface ActivityCache {
  isActivityLoading: boolean;
  activities: IActivityItem[];
  hasActivities: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  reload: () => void;
  addActivityToCache: (newActivity: IActivityItem) => void;
}

export const useActivityCache = (searchString: string = ''): ActivityCache => {
  const activityService = useActivityService();
  const { myTeams } = loggedInUser.useController();
  const queryClient = useQueryClient();

  const teamIds = React.useMemo(() => myTeams.map((t) => t.id), [myTeams]);

  const query = useInfiniteQuery({
    queryKey: ['recentActivities', ...myTeams, searchString],
    queryFn: ({ pageParam }) => activityService.getRecentActivities(teamIds, searchString, pageParam, 20),
    initialPageParam: 0,
    enabled: Boolean(teamIds.length),
    staleTime: MILLISECONDS.MINUTE * 30,
    getNextPageParam: (lastPage) => (lastPage.activityCount >= lastPage.pageSize ? lastPage.pageIncrement + 1 : undefined)
  });

  const reload = () => {
    queryClient.resetQueries({ queryKey: ['recentActivities', ...myTeams] });
  };

  const addActivityToCache = (newActivity: IActivityItem) => {
    queryClient.setQueryData(['recentActivities', ...myTeams, searchString], (oldData: any) => {
      const allActivities = [newActivity, ...flatten(query.data?.pages.map((r) => r.activities))];
      const pagedActivities = chunk(allActivities, 20);

      const newPagesArray = oldData.pages.map((page: any, index: number) => {
        return {
          ...page,
          activities: pagedActivities[index]
        };
      });

      return {
        pages: newPagesArray,
        pageParams: oldData.pageParams
      };
    });
  };

  const activities = query.data?.pages ? flatten(query.data?.pages.map((r) => r.activities)) : [];
  const isActivityLoading = query.isLoading;
  const hasActivities = Boolean(activities.length);
  const hasNextPage = query.hasNextPage && !isActivityLoading;
  const fetchNextPage = query.fetchNextPage;

  return {
    isActivityLoading,
    activities,
    hasActivities,
    hasNextPage,
    fetchNextPage,
    reload,
    addActivityToCache
  };
};
