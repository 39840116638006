import { IActivityItem } from 'types';
import { Box, Grid, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import UserAvatar from 'components/UserAvatar';
import { loggedInUser, useCurrentWidth } from 'hooks';
import { useNavigate } from 'react-router';
import { dispatch, resetMemberState, setCurrentMemberTask } from 'store';
import { activityTypeEnum } from 'types/store/activityEnums';
import { formatUtcToLocalDateTime } from 'utils/helpers/dateTimeHelper';
import ShowMoreTextContent from 'components/ShowMoreTextContent';

export const ActivityItem: React.VFC<IActivityItem> = (props) => {
  const { activityInitiatorName, activityDate, userId, teamId, teamName, activityTypeName, taskId, taskName, message } = props;

  const { userName } = loggedInUser.useController();

  const navigate = useNavigate();
  const browserWidth = useCurrentWidth();
  const theme = useTheme();
  const [showSmall, setShowSmall] = useState(browserWidth <= theme.breakpoints.values.md);

  useEffect(() => {
    setShowSmall(browserWidth <= theme.breakpoints.values.lg && browserWidth >= theme.breakpoints.values.md);
  }, [browserWidth, theme.breakpoints.values]);

  const itemDesc = () => {
    const activityDescription =
      activityTypeName === activityTypeEnum.Mention && !teamId
        ? `${activityInitiatorName} has mentioned you on`
        : activityTypeName === activityTypeEnum.Mention && teamId
        ? `${activityInitiatorName} has mentioned ${teamName} on`
        : activityTypeName === activityTypeEnum.Assignment && !teamId
        ? `${activityInitiatorName} has assigned you to`
        : activityTypeName === activityTypeEnum.Assignment && !userId
        ? `${activityInitiatorName} has assigned ${teamName} to`
        : activityTypeName === activityTypeEnum.Responded
        ? `${activityInitiatorName} has responded to`
        : activityTypeName === activityTypeEnum.Updated
        ? `${activityInitiatorName} has updated`
        : activityTypeName === activityTypeEnum.Closed
        ? `${activityInitiatorName} has closed`
        : activityTypeName === activityTypeEnum.ReOpened
        ? `${activityInitiatorName} has reopened`
        : activityTypeName === activityTypeEnum.Reminder && !teamId
        ? `You have a reminder for`
        : activityTypeName === activityTypeEnum.Reminder && !userId
        ? `${teamName} has a reminder for`
        : activityTypeName === activityTypeEnum.Created
        ? `${activityInitiatorName} has created`
        : activityTypeName === activityTypeEnum.SmsFailed
        ? `SMS Failure received on`
        : `${activityInitiatorName} has commented on`;

    const handleTaskLink = () => {
      dispatch(resetMemberState());
      dispatch(setCurrentMemberTask(null));
      navigate(`/task`, {
        state: {
          paramsData: {
            taskId: taskId
          }
        }
      });
    };

    return (
      <Typography align="left" fontSize={showSmall ? 10 : 12} variant="subtitle1">
        {activityDescription} <Link onClick={() => handleTaskLink()}>#{taskName}</Link>{' '}
      </Typography>
    );
  };

  let msg = message === undefined ? activityTypeName : message;
  msg = msg.replace(/(<([^>]+)>)/gi, '');

  let activityAvatarDisplayName = activityInitiatorName;
  if (activityTypeName === activityTypeEnum.Reminder) {
    activityAvatarDisplayName = teamName ?? userName ?? activityInitiatorName;
  }
  return (
    <Grid item xs={12} paddingBottom={1} style={{ borderBottom: `1px solid ${theme.palette.secondary[200]}` }}>
      <Grid container spacing={2}>
        <Grid item>
          <Box sx={{ position: 'relative' }}>
            <UserAvatar displayName={activityAvatarDisplayName} size={showSmall ? 'md' : 'lg'} />
          </Box>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant="caption" fontSize={showSmall ? 10 : 12} color="secondary">
            {formatUtcToLocalDateTime(activityDate)} <br />
          </Typography>
          {itemDesc()}
          <Typography component="span" align="left" fontSize={showSmall ? 10 : 12} variant="body1">
            <ShowMoreTextContent textContent={msg} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivityItem;
