import { Stack, Typography } from '@mui/material';
import dashboardContext from 'views/Dashboard/useDashboardContext';
import ApexSLAChart from './ApexSLAChart';
import EmptyFilter from './EmptyFilter';

const PriorityFilter: React.VFC = () => {
  const { priorityRedrawKey, hasPriorityTasks, priorityCounts, setPriorityFilter } = dashboardContext.useController();

  if (!hasPriorityTasks) {
    return <EmptyFilter label="Emergency Tasks" />;
  }

  return (
    <Stack data-testid={'member-filter-priority'}>
      <Typography variant="h5">Emergency Tasks</Typography>
      <ApexSLAChart key={`priorityFilter${priorityRedrawKey}`} slaCounts={priorityCounts} onSLASelected={setPriorityFilter} />
    </Stack>
  );
};

export default PriorityFilter;
