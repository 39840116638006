import { MILLISECONDS, ITaskParticipant } from 'types';
import { SearchOption, SearchProps, SearchAndShowProps } from 'packages/search-and-show';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useUserService } from 'hooks';
import { useDebouncedState } from 'sollishealth.core.react';

export const useSearchUsers = (props: SearchProps): SearchAndShowProps => {
  const { id, value, onSelect, disabled } = props;
  const [debouncedSearchString] = useDebouncedState('');
  const userService = useUserService();

  const currentUserOptions = useMemo(
    () =>
      !value
        ? undefined
        : value.map((participant: ITaskParticipant) => {
            return {
              id: participant.id,
              displayName: participant.displayName,
              optionObject: participant
            } as SearchOption;
          }),
    [value]
  );

  const searchQuery = useQuery({
    queryKey: ['searchUsers', debouncedSearchString],
    queryFn: () => userService.findUsers(debouncedSearchString),
    select: (data) =>
      data.map((userOptions) => {
        return {
          id: userOptions.id,
          displayName: userOptions.displayName,
          optionObject: userOptions
        } as SearchOption;
      }),
    enabled: Boolean(debouncedSearchString && debouncedSearchString.length >= 2),
    staleTime: MILLISECONDS.HOUR * 1
  });

  const showLoading = (searchQuery.isPending && debouncedSearchString?.length >= 2) || searchQuery.isLoading;

  return {
    id,
    placeHolder: 'Add Participant...',
    value: currentUserOptions,
    searchOptions: searchQuery?.data,
    onSelect,
    isDisabled: disabled,
    showLoading
  };
};
