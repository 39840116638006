import { useMemo } from 'react';
import { MILLISECONDS, ITaskAssignedTo } from 'types';
import { SearchOption } from 'packages/search-and-show';
import { SearchProps, SearchAndShowProps } from 'packages/search-and-show/definitions';
import { useQuery } from '@tanstack/react-query';
import { useUserService } from 'hooks';
import { useDebouncedState } from 'sollishealth.core.react';

export const useSearchGroupUsers = (props: SearchProps): SearchAndShowProps => {
  const { id, value, onSelect, defaultOptions, disabled, showOptions, onClose, errors, touched } = props;
  const [debouncedSearchString] = useDebouncedState('');
  const userService = useUserService();

  const currentOwnerOption = useMemo(() => {
    return !value
      ? undefined
      : ({
          id: `${value?.groupId}${value?.userId ?? ''}`,
          displayName: `${value?.displayName}`,
          optionObject: value
        } as SearchOption);
  }, [value]);

  const defaultOwnerOptions = useMemo(
    () =>
      !defaultOptions
        ? undefined
        : defaultOptions.map((t: ITaskAssignedTo) => {
            return {
              id: `${t?.groupId}${t?.userId ?? ''}`,
              displayName: `${t?.displayName}`,
              optionObject: t
            } as SearchOption;
          }),
    [defaultOptions]
  );

  const searchQuery = useQuery({
    queryKey: ['searchGroupUsers', debouncedSearchString],
    queryFn: () => userService.findOwners(debouncedSearchString),
    select: (data) =>
      data.map((userOptions) => {
        return {
          id: `${userOptions.groupId}${userOptions.userId ?? ''}`,
          displayName: userOptions.displayName,
          optionObject: userOptions
        } as SearchOption;
      }),
    enabled: Boolean(debouncedSearchString && debouncedSearchString.length >= 2),
    staleTime: MILLISECONDS.HOUR * 1
  });

  const showLoading = (searchQuery.isPending && debouncedSearchString?.length >= 2) || searchQuery.isLoading;

  return {
    id,
    placeHolder: 'Assign Task To...',
    value: currentOwnerOption,
    onSelect,
    defaultOptions: defaultOwnerOptions,
    searchOptions: searchQuery?.data,
    showOptions,
    onClose,
    showLoading,
    isDisabled: disabled,
    errors,
    touched
  };
};
