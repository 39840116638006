import { Typography } from '@mui/material';

const NoActivity = () => (
  <Typography variant="h5" style={{ fontStyle: 'italic', margin: '5px', textAlign: 'center' }}>
    You have no recent activity
  </Typography>
);

const NoActivityFound = () => (
  <Typography variant="h5" style={{ fontStyle: 'italic', margin: '5px', textAlign: 'center' }}>
    No recent activity matches the search provided
  </Typography>
);

interface Props {
  search?: string;
  visible?: boolean;
}

export const NoActivityIndicator = ({ search, visible }: Props) => {
  if (!visible) {
    return null;
  }
  return search ? <NoActivityFound /> : <NoActivity />;
};

export default NoActivityIndicator;
