import { useTaskService } from '../../../hooks';
import { SearchAndShowProps, SearchOption } from 'packages/search-and-show';
import { MILLISECONDS, ILinkedTask } from 'types';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDebouncedState } from 'sollishealth.core.react';

export interface SearchTasksProps {
  id: string;
  placeholder: string;
  taskId?: string;
  currentTasks?: ILinkedTask[];
  defaultOptions?: ILinkedTask[];
  disabled?: boolean;
  allowMultiple?: boolean;
  showLoading?: boolean;
  handleTaskClicked?: (linkedTask: ILinkedTask) => void;
  onSelect: (event: any) => void;
}

export interface SearchContext {
  id: string;
  placeholder: string;
  currentOption?: SearchOption[];
  defaultOptions?: SearchOption[];
  disabled?: boolean;
  allowMultiple?: boolean;
  showLoading?: boolean;
  handleSearch?: (searchValue: string) => Promise<SearchOption[]>;
  onSelect: (event: any) => void;
}

export const useSearchTasks = (props: SearchTasksProps): SearchAndShowProps => {
  const { id, placeholder, showLoading, allowMultiple, currentTasks, defaultOptions, disabled, taskId, onSelect } = props;
  const [debouncedSearchString] = useDebouncedState('');
  const taskService = useTaskService();

  const mapLinkedTaskToSearchOption = (linkedTask: ILinkedTask): SearchOption => {
    return {
      id: linkedTask.taskId,
      displayName: `${linkedTask.taskName}-${linkedTask.taskTypeName}-${linkedTask.taskDescription?.substring(
        0,
        linkedTask.taskDescription.length < 50 ? linkedTask.taskDescription.length - 1 : 50
      )}`,
      optionDisabled: taskId === linkedTask.taskId,
      optionObject: linkedTask
    } as SearchOption;
  };

  const currentTaskOptions = useMemo(
    () => (!currentTasks ? undefined : currentTasks.map((linkedTask: ILinkedTask) => mapLinkedTaskToSearchOption(linkedTask))),
    [currentTasks]
  );

  const defaultTaskOptions = useMemo(
    () => (!defaultOptions ? undefined : defaultOptions.map((linkedTask: ILinkedTask) => mapLinkedTaskToSearchOption(linkedTask))),
    [defaultOptions]
  );

  const searchQuery = useQuery({
    queryKey: ['searchTasks', debouncedSearchString],
    queryFn: () => taskService.findTasks(debouncedSearchString),
    select: (data) => data.map((taskOption) => mapLinkedTaskToSearchOption(taskOption)),
    enabled: Boolean(debouncedSearchString && debouncedSearchString.length >= 2),
    staleTime: MILLISECONDS.MINUTE * 5
  });

  const isLoading = showLoading || (searchQuery.isPending && debouncedSearchString?.length >= 2) || searchQuery.isLoading;

  return {
    id,
    value: currentTaskOptions,
    defaultOptions: defaultTaskOptions,
    searchOptions: searchQuery?.data,
    placeHolder: placeholder,
    allowMultiple,
    isDisabled: disabled,
    showLoading: isLoading,
    onSelect
  };
};
