import { useSelector } from 'react-redux';

// material-ui
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material';

// project import
// import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import HomeIcon from '@mui/icons-material/Home';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EmailIcon from '@mui/icons-material/Email';
import PeopleIcon from '@mui/icons-material/People';
import SpaIcon from '@mui/icons-material/Spa';
import appSettings from 'constants/appsettings.json';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PhoneIcon from '@mui/icons-material/Phone';
// types
import { RootStateProps } from 'types/root';
import { Link } from 'react-router-dom';
import { dispatch, setIsLoggingOut } from 'store';
import { MedParterSearchDrawerButton } from 'features/med-partner-search';
import { DrawerButton } from 'components/DrawerButton';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex'
        }
      }}
    >
      <Navigation />
      <List>
        <ListItem component={Link} to="/dashboard">
          <ListItemButton>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <HomeIcon></HomeIcon>
            </ListItemIcon>
            <ListItemText primary="Dashboard" sx={{ color: theme.palette.text.primary, opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <MedParterSearchDrawerButton
          drawerButtonComponent={DrawerButton}
        />
        <ListItem>
          <ListItemButton
            onClick={() => {
              window.open(appSettings.navigation.reportsUrl, '_blank');
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <SummarizeIcon></SummarizeIcon>
            </ListItemIcon>
            <ListItemText primary="Reports" sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              window.open('https://outlook.office365.com/mail/', '_blank');
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <EmailIcon></EmailIcon>
            </ListItemIcon>
            <ListItemText primary="Email" sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              window.open('https://teams.microsoft.com/', '_blank');
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <PeopleIcon></PeopleIcon>
            </ListItemIcon>
            <ListItemText primary="Teams" sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              window.open(appSettings.athena.url, '_blank');
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <SpaIcon></SpaIcon>
            </ListItemIcon>
            <ListItemText primary="Athena" sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              window.open('https://sollishealth.mytalkdesk.com/', '_blank');
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <PhoneIcon></PhoneIcon>
            </ListItemIcon>
            <ListItemText primary="Talkdesk" sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              window.open(appSettings.navigation.feedbackUrl, '_blank');
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <SupportAgentIcon></SupportAgentIcon>
            </ListItemIcon>
            <ListItemText primary="Support" sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ mt: 4 }} style={{ borderRadius: 4, backgroundColor: 'black' }} />
        <ListItem>
          <ListItemButton
            onClick={() => {
              dispatch(setIsLoggingOut(true));
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <PersonIcon></PersonIcon>
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      {drawerOpen && !matchDownMD && <></>}
    </SimpleBar>
  );
};

export default DrawerContent;
