import { DrawerButtonProps } from 'components/DrawerButton';
import React from 'react';
import LocalPharmacy from '@mui/icons-material/LocalPharmacy';
import { useNavigate } from 'react-router-dom';
import { routeUrl } from '../constants/route-url';
import { FeatureWrapper } from './feature-wrapper';

interface Props {
    drawerButtonComponent: React.FC<DrawerButtonProps>;
}

export const LeftNavLink = ({
    drawerButtonComponent: DrawerButton
}: Props) => {
    const navigate = useNavigate();
    const onClick = () => navigate(routeUrl);

    return (
        <FeatureWrapper>
            <DrawerButton
                label='Medical Partner Directory'
                iconComponent={LocalPharmacy}
                onClick={onClick}
            />
        </FeatureWrapper>
    );
};