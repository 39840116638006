import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Box, ClickAwayListener, Paper, Popper, Tooltip, Grid, useMediaQuery } from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { msalInstance } from 'contexts/Provider';
import settings from 'constants/appsettings.json';
import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getAuthToken } from 'services/Auth/Auth.service';
import { IActivityItem } from 'types';
import ActivityItem from 'views/Dashboard/components/RecentActivity/components/ActivityItem';
import _ from 'lodash';
import { loggedInUser, useActivityCache, useOnUpdateEffect, useSoundEffects } from 'hooks';

const Notification = () => {
  const theme = useTheme();
  const { addActivityToCache } = useActivityCache();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const soundEffects = useSoundEffects();

  const anchorRef = useRef<any>(null);
  const [connection, setConnection] = useState<any>(null);
  const [items, setItems] = useState<IActivityItem[]>([]);
  const latestItem = useRef<IActivityItem[] | null>(null);
  const [open, setOpen] = useState(false);
  const { userId, userGroups, userAccount } = loggedInUser.useController();

  latestItem.current = items;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    createConnection();
  }, []);

  const createConnection = () => {
    if (userAccount) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(settings.apiDetails.signalRUri, {
          accessTokenFactory: async () => await getAuthToken(msalInstance, userAccount, settings.serviceScopes.ActivityService)
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  };

  //  Only play notification when there are items!
  useOnUpdateEffect(() => {
    if (items && items.length) {
      soundEffects.invokeNotificationSound();
      addActivityToCache(items[0]);
    }
  }, [items]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((_result: any) => {
          connection.send('Echo');

          userGroups.forEach((val) => {
            connection.invoke('JoinGroup', val);
          });

          connection.on('Activity', (itemsJson: string) => {
            let item: any = JSON.parse(itemsJson);
            item = camelCaseKeys(item);
            let activity: IActivityItem = item as IActivityItem;

            if (activity.activityInitiatorId === userId) {
              return;
            }

            // UserId or TeamId will always be set on Activity
            if (activity?.userId !== userId && !userGroups.find((g) => g === activity?.teamId)) {
              return;
            }

            const updatedActivity = [...latestItem.current!];
            updatedActivity.unshift(activity);
            setItems(updatedActivity);
          });
        })

        .catch((e: any) => console.log('Connection failed: ', e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  const camelCaseKeys: any = (obj: any) => {
    if (!_.isObject(obj)) {
      return obj;
    } else if (_.isArray(obj)) {
      return obj.map((v) => camelCaseKeys(v));
    }
    return _.reduce(
      obj,
      (r, v, k) => {
        return {
          ...r,
          [_.camelCase(k)]: camelCaseKeys(v)
        };
      },
      {}
    );
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={items.length} color={'primary'}>
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 400,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title={items.length > 0 ? 'Notifications' : 'No New Notifications'}
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {items.length > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => {
                              setItems([]);
                              handleToggle();
                            }}
                          >
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  {items.length > 0 && (
                    <Grid item xs={12} style={{ padding: '10px' }}>
                      <Grid container rowSpacing={3} spacing={3} alignItems="center" style={{ maxHeight: '450px', overflowY: 'auto' }}>
                        {items.map((item: any, index: number) => (
                          <ActivityItem {...item} key={index} />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
