import { SLA_STATUS, ITask } from 'types';
import { taskPriorityEnum } from 'types/store/taskEnums';

//  Task filtering
const isTaskSlaStatus = (slaStatus: SLA_STATUS) => (task: ITask) => task.slaStatus === slaStatus;
const isTaskVip = (task: ITask) => task.vipMember;
const isTaskEmergency = (task: ITask) => task.taskPriorityId === taskPriorityEnum.Emergency;

const isTaskVipWithSlaStatus = (slaStatus: SLA_STATUS) => (task: ITask) => isTaskVip(task) && isTaskSlaStatus(slaStatus)(task);
const isTaskEmergencyWithSlaStatus = (slaStatus: SLA_STATUS) => (task: ITask) => isTaskEmergency(task) && isTaskSlaStatus(slaStatus)(task);

//  task counts generation
export const createSLACounts = (tasks: ITask[]) => [
  tasks.filter(isTaskSlaStatus(SLA_STATUS.BREACH)).length,
  tasks.filter(isTaskSlaStatus(SLA_STATUS.WARNING)).length,
  tasks.filter(isTaskSlaStatus(SLA_STATUS.COMPLIANT)).length
];

export const createVIPTaskCounts = (tasks: ITask[]) => [
  tasks.filter(isTaskVipWithSlaStatus(SLA_STATUS.BREACH)).length,
  tasks.filter(isTaskVipWithSlaStatus(SLA_STATUS.WARNING)).length,
  tasks.filter(isTaskVipWithSlaStatus(SLA_STATUS.COMPLIANT)).length
];

export const createPriorityTaskCounts = (tasks: ITask[]) => [
  tasks.filter(isTaskEmergencyWithSlaStatus(SLA_STATUS.BREACH)).length,
  tasks.filter(isTaskEmergencyWithSlaStatus(SLA_STATUS.WARNING)).length,
  tasks.filter(isTaskEmergencyWithSlaStatus(SLA_STATUS.COMPLIANT)).length
];
