import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { WarningFilled, FireFilled, CheckCircleFilled } from '@ant-design/icons';
import { SLA_STATUS } from 'types';

interface SLAFieldProps {
  slaStatus?: string;
}

export const SLAIcon: React.FC<SLAFieldProps> = ({ slaStatus }) => {
  const theme = useTheme();

  if (slaStatus === SLA_STATUS.WARNING) {
    return <WarningFilled style={{ color: theme.palette.warning.main }} />;
  } else if (slaStatus === SLA_STATUS.BREACH) {
    return <FireFilled style={{ color: theme.palette.error.main }} />;
  } else {
    return <CheckCircleFilled style={{ color: theme.palette.secondary[600] }} />;
  }
};

export const SLAIconAndLabel: React.FC<SLAFieldProps> = ({ slaStatus }) => {
  const theme = useTheme();

  if (slaStatus === SLA_STATUS.WARNING) {
    return (
      <Typography variant="h5">
        <WarningFilled style={{ color: theme.palette.warning.main, paddingRight: 5 }} />
        {SLA_STATUS.WARNING}
      </Typography>
    );
  } else if (slaStatus === SLA_STATUS.BREACH) {
    return (
      <Typography variant="h5">
        <FireFilled style={{ color: theme.palette.error.main, paddingRight: 5 }} />
        {SLA_STATUS.BREACH}
      </Typography>
    );
  } else {
    return (
      <Typography variant="h5">
        <CheckCircleFilled style={{ color: theme.palette.secondary[600], paddingRight: 5 }} />
        {SLA_STATUS.COMPLIANT}
      </Typography>
    );
  }
};
