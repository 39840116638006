import { createContextForController } from 'react-controller-context';
import { useTeamTasksCache } from '../../hooks/caches/use-team-tasks';
import { DASHBOARD_SUMMARY_VIEW, ITask, IUpdateTaskAssignedTo, SLA_STATUS } from 'types';
import { useEffect, useMemo, useState } from 'react';
import { taskPriorityEnum } from 'types/store/taskEnums';
import useSessionStorageState from 'use-session-storage-state';
import { createPriorityTaskCounts, createVIPTaskCounts, createSLACounts } from './task-utilities';
import moment from 'moment';
import { loggedInUser } from 'hooks';

interface DashboardContext {
  isTasksLoading: boolean;
  isTasksRefreshing: boolean;
  filteredTasks: ITask[];
  hasSummaryTasks: boolean;
  hasTasks: boolean;
  summaryFilter: DASHBOARD_SUMMARY_VIEW | null;
  slaFilter: SLA_STATUS | null;
  vipFilter: boolean;
  priorityFilter: boolean;
  myTasksCount: number;
  teamTasksCount: number;
  slaCounts: number[];
  vipCounts: number[];
  hasVipTasks: boolean;
  priorityCounts: number[];
  hasPriorityTasks: boolean;
  vipRedrawKey: string;
  priorityRedrawKey: string;
  activeTeamId: string;
  setCurrentTeam: (teamId: string) => void;
  setSummaryFilter: (summaryFilter: DASHBOARD_SUMMARY_VIEW) => void;
  setSLAFilter: (slaFilter: SLA_STATUS) => void;
  setVIPFilter: (slaFilter: SLA_STATUS) => void;
  setPriorityFilter: (slaFilter: SLA_STATUS) => void;
  reloadTasks: () => void;
  handleUpdateAssignedTo: (assignedTo: IUpdateTaskAssignedTo) => Promise<void>;
}

interface ITaskFilters {
  slaStatus: SLA_STATUS | null;
  vipStatus: boolean;
  summaryView: DASHBOARD_SUMMARY_VIEW | null;
  priorityStatus: boolean;
  vipRedrawKey: string;
  priorityRedrawKey: string;
}

const initialFilters: ITaskFilters = {
  slaStatus: null,
  vipStatus: false,
  summaryView: null,
  priorityStatus: false,
  vipRedrawKey: '',
  priorityRedrawKey: ''
};

export const useDashboardController = (): DashboardContext => {
  const { userId, hasTeams, myTeams } = loggedInUser.useController();

  const [activeTeamId, setActiveTeamId] = useSessionStorageState<string>('activeTeamId', { defaultValue: '' });
  const {
    teamTasks,
    isTasksLoading,
    isTasksRefreshing,
    hasTasks,
    teamTasksCount,
    myTasksCount,
    handleUpdateAssignedTo,
    reloadTeamTasks,
    refreshTeamTasks
  } = useTeamTasksCache(activeTeamId);

  const [summaryViewCache, setSummaryViewCache] = useSessionStorageState<DASHBOARD_SUMMARY_VIEW | null>('summaryView', {
    defaultValue: null
  });
  const [teamTaskFilter, setTeamTaskFilter] = useState<ITaskFilters>({ ...initialFilters, summaryView: summaryViewCache });

  useEffect(() => {
    if (hasTeams && !Boolean(activeTeamId)) {
      let teamId = '';
      if (myTeams.length > 1) {
        teamId = 'ALL';
      } else {
        teamId = myTeams[0].id;
      }
      setActiveTeamId(teamId);
    }
  }, [myTeams, hasTeams]);

  useEffect(() => {
    setSummaryViewCache(teamTaskFilter.summaryView);
  }, [teamTaskFilter.summaryView]);

  // When a team is selected, the summary view is cleared...
  // The default is User but if User has no tasks, it should swap to Team
  useEffect(() => {
    if (hasTasks && !Boolean(teamTaskFilter.summaryView)) {
      if (teamTasksCount > 0 && myTasksCount === 0) {
        setSummaryFilter(DASHBOARD_SUMMARY_VIEW.TEAM);
      } else {
        setSummaryFilter(DASHBOARD_SUMMARY_VIEW.USER);
      }
    }
  }, [hasTasks, teamTasksCount, myTasksCount]);

  const reloadTasks = () => {
    reloadTeamTasks();
    setTeamTaskFilter({ ...initialFilters, summaryView: teamTaskFilter.summaryView });
  };

  const setCurrentTeam = (teamId: string) => {
    setActiveTeamId(teamId);
    setTeamTaskFilter(initialFilters);
  };

  const setSummaryFilter = (summaryFilter: DASHBOARD_SUMMARY_VIEW) => {
    // Refresh the query when changing task view
    refreshTeamTasks();
    setTeamTaskFilter({ ...initialFilters, summaryView: summaryFilter });
  };

  const setSLAFilter = (slaFilter: SLA_STATUS) => {
    let updatedSLAStatus = slaFilter === teamTaskFilter.slaStatus ? null : slaFilter;

    // The Apex charts do not redraw if SLA is cleared while VIP or Priority is active
    // This will force a redraw and clear the chart
    let updateVIPKey = teamTaskFilter.vipRedrawKey;
    let updatePriorityKey = teamTaskFilter.priorityRedrawKey;
    if (updatedSLAStatus == null && (teamTaskFilter.priorityStatus || teamTaskFilter.vipStatus)) {
      const newKey = moment.utc().format('x');

      if (teamTaskFilter.priorityStatus) {
        updatePriorityKey = newKey;
      }

      if (teamTaskFilter.vipStatus) {
        updateVIPKey = newKey;
      }
    }

    const newTeamTaskFilter = {
      ...initialFilters,
      summaryView: teamTaskFilter.summaryView,
      slaStatus: updatedSLAStatus,
      vipRedrawKey: updateVIPKey,
      priorityRedrawKey: updatePriorityKey
    };
    setTeamTaskFilter(newTeamTaskFilter);
  };

  const setVIPFilter = (vipSLA: SLA_STATUS) =>
    setTeamTaskFilter((existingTeamFilter) => {
      return {
        ...existingTeamFilter,
        vipStatus: !existingTeamFilter.vipStatus,
        slaStatus: !existingTeamFilter.vipStatus || existingTeamFilter.priorityStatus ? vipSLA : null
      };
    });

  const setPriorityFilter = (prioritySLA: SLA_STATUS) =>
    setTeamTaskFilter((existingTeamFilter) => {
      return {
        ...existingTeamFilter,
        priorityStatus: !existingTeamFilter.priorityStatus,
        slaStatus: existingTeamFilter.vipStatus || !existingTeamFilter.priorityStatus ? prioritySLA : null
      };
    });

  const summaryTasks = useMemo(
    () =>
      !Boolean(teamTaskFilter.summaryView)
        ? []
        : teamTasks.filter((t) => (teamTaskFilter.summaryView === DASHBOARD_SUMMARY_VIEW.USER ? t.assignedToUserId === userId : true)),
    [teamTasks, teamTaskFilter.summaryView]
  );
  const hasSummaryTasks = useMemo(() => Boolean(summaryTasks.length), [summaryTasks]);

  const slaCounts = useMemo(() => createSLACounts(summaryTasks), [summaryTasks]);

  const filteredTasks = useMemo(
    () =>
      summaryTasks
        .filter((t) => !teamTaskFilter.slaStatus || t.slaStatus === teamTaskFilter.slaStatus)
        .filter((t) => !teamTaskFilter.vipStatus || t.vipMember)
        .filter((t) => !teamTaskFilter.priorityStatus || t.taskPriorityId === taskPriorityEnum.Emergency),
    [summaryTasks, teamTaskFilter.slaStatus, teamTaskFilter.vipStatus, teamTaskFilter.priorityStatus]
  );

  const vipCounts = useMemo(() => createVIPTaskCounts(filteredTasks), [filteredTasks]);
  const hasVipTasks = useMemo(() => Boolean(vipCounts.find((c) => c > 0)), [vipCounts]);

  const priorityCounts = useMemo(() => createPriorityTaskCounts(filteredTasks), [filteredTasks]);
  const hasPriorityTasks = useMemo(() => Boolean(priorityCounts.find((c) => c > 0)), [priorityCounts]);

  return {
    isTasksLoading,
    isTasksRefreshing,
    filteredTasks,
    hasSummaryTasks,
    hasTasks,
    summaryFilter: teamTaskFilter.summaryView,
    slaFilter: teamTaskFilter.slaStatus,
    vipFilter: teamTaskFilter.vipStatus,
    priorityFilter: teamTaskFilter.priorityStatus,
    vipRedrawKey: teamTaskFilter.vipRedrawKey,
    priorityRedrawKey: teamTaskFilter.priorityRedrawKey,
    myTasksCount,
    teamTasksCount,
    slaCounts,
    vipCounts,
    hasVipTasks,
    priorityCounts,
    hasPriorityTasks,
    activeTeamId,
    reloadTasks,
    setSummaryFilter,
    setSLAFilter,
    setVIPFilter,
    setPriorityFilter,
    setCurrentTeam,
    handleUpdateAssignedTo
  };
};

const dashboardContext = createContextForController(useDashboardController);

export default dashboardContext;
