import { useActivityCache, useConversationTypesCache, useRegionsCache, useStaffMembersCache, useTaskLookupsCache } from 'hooks';

// The job of this component hook is to trigger/instantiate the different caches the app will use
// to save on having to load that at the time the data is needed
export const CachePreloader: React.VFC = () => {
  useRegionsCache();
  useStaffMembersCache();

  useActivityCache();

  useTaskLookupsCache();
  useConversationTypesCache();

  return null;
};
